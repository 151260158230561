import F2 from '@antv/f2/lib/index-all';
export default {
  name: 'AreaRateChart',
  props: ['data', 'id'],
  data() {
    return {
      chart: null,
      colors: ['#3aa0ff', '#4ecb73', '#fbd337']
    };
  },
  mounted() {
    setTimeout(() => {
      this.initChart();
    }, 0);
  },
  methods: {
    initChart() {
      this.$nextTick(() => {
        let width = this.$refs.chart.offsetWidth;
        let height = this.$refs.chart.offsetHeight;
        // console.log(this.data);
        this.chart = new F2.Chart({
          width,
          height,
          id: this.id,
          pixelRatio: window.devicePixelRatio
        }).legend({
          align: 'center',
          marker: {
            symbol: 'square'
          },
          // clickable: false,
          position: 'bottom'
        }).source(this.data).coord({
          transposed: true
        }).tooltip({});
        console.log('this.data: ', this.data);
        this.chart.interval().position('city*value').color('name').adjust({
          type: 'dodge',
          marginRatio: -1 // 设置分组间柱子的间距
        }).size(12);
        this.chart.render();
      });
    }
  }
};
import AreaStackedChart from '@/components/AreaStackedChart';
import { provinceFind, cityFind, taskStaticsTable, taskStaticsGraph } from '@/api/wxproject';
import Selector from '@/components/Selector';
import dayjs from 'dayjs';
export default {
  components: {
    Selector,
    AreaStackedChart
  },
  data() {
    return {
      activeNames: [],
      activeName: '1',
      provinceList: [],
      cityList: [],
      province: '',
      city: '',
      tableData: {},
      totalStatics: [],
      provinces: [],
      graphData: [],
      date: '',
      showPicker: false
    };
  },
  mounted() {
    document.title = '省市区活跃统计';
    this.date = this.$route.query.date;
    this.getProvince();
    this.getData();
    this.getData1();
  },
  computed: {
    minDate() {
      return new Date(this.$route.query.minDate.split('-').join('/'));
    },
    maxDate() {
      // if (dayjs(this.$route.query.maxDate).valueOf() < Date.now()) {
      //   return new Date(this.$route.query.maxDate.split('-').join('/'));
      // }
      return new Date();
    }
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    getData() {
      taskStaticsGraph({
        projectCode: this.$route.query.projectCode,
        date: this.date
      }).then(res => {
        if (res.data.success) {
          var _this$$refs$chart;
          console.log('res.data: ', res.data);
          const graphData = [];
          res.data.data.cityList.forEach(item => {
            console.log('item: ', item);
            if (res.data.data.projectCategory === 'DP') {
              graphData.unshift({
                city: item.city,
                value: item.attEndDp || 0,
                name: '活跃完成'
              });
              graphData.unshift({
                city: item.city,
                value: item.attDayDP || 0,
                name: '已活跃'
              });
              graphData.unshift({
                city: item.city,
                value: item.attExpect || 0,
                name: '应活跃'
              });
            } else if (res.data.data.projectCategory === 'LHDG') {
              graphData.unshift({
                city: item.city,
                value: item.attEndLHDG || 0,
                name: '活跃完成'
              });
              graphData.unshift({
                city: item.city,
                value: item.attDayLHDG || 0,
                name: '已活跃'
              });
            }
          });
          this.graphData = graphData;
          console.log('graphData: ', graphData);
          (_this$$refs$chart = this.$refs.chart) === null || _this$$refs$chart === void 0 ? void 0 : _this$$refs$chart.initChart();
        }
      });
    },
    getData1() {
      taskStaticsTable({
        projectCode: this.$route.query.projectCode,
        date: this.date,
        province: this.province,
        city: this.city
      }).then(res => {
        if (res.data.success) {
          this.tableData = res.data.data;
          this.totalStatics = res.data.data.totalStatics;
          this.provinces = res.data.data.provinces;
          this.activeNames = this.provinces.map(item => item.province);
        }
      });
    },
    getProvince() {
      provinceFind().then(res => {
        this.provinceList = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.code
          };
        });
        this.provinceList.unshift({
          label: '不限',
          value: ''
        });
      });
    },
    getCity() {
      console.log(this.province);
      cityFind({
        provinceCode: this.province
      }).then(res => {
        this.cityList = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.code
          };
        });
        this.cityList.unshift({
          label: '不限',
          value: ''
        });
        this.city = '';
        this.getData1();
      });
    },
    onConfirm(value) {
      this.date = dayjs(value).format('YYYY-MM-DD');
      this.showPicker = false;
      this.getData();
      this.getData1();
    },
    tabChange(val) {
      if (val === '2') {
        var _this$$refs$chart2;
        (_this$$refs$chart2 = this.$refs.chart) === null || _this$$refs$chart2 === void 0 ? void 0 : _this$$refs$chart2.initChart();
      }
    }
  }
};